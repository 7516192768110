.category {
  padding: 0;
  /* padding-bottom: 6rem; */
}
.category-top {
  position: relative;
  /* padding-bottom: 3.75rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.category-banner {
  width: 100%;

  position: relative;
}
.category-banner img {
  width: 100%;
  height: 28vh;
  opacity: 0.7;
  /* border-radius: 12px; */
  object-fit: cover;
}
.category-pic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  /* margin-top: -100px; */
}
.category-pic img {
  border: 6px solid var(--color-bg);
  border-radius: 50%;
  width: 70%;
}
.category-banner h3 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  color: #ffffff;
  position: absolute;

  margin-top: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.category-bottom {
  /* padding: 4rem 6rem; */
}
.category-bottom-input {
  display: flex;
  justify-content: center;
  align-items: center;
}
.category-bottom-input input {
  background: #1b1a21;
  border-radius: 10px;
  border: none;
  padding: 7px 19px 7px 15px;
  font-family: var(--font-family);
  outline: none;
  color: whitesmoke;
  flex: 0.3;
  display: flex;
  margin-right: 1rem;
}
.category-bottom-input select {
  background: #1b1a21;
  border-radius: 10px;
  border: none;
  padding: 7px 19px 7px 15px;
  font-family: var(--font-family);
  outline: none;
  color: whitesmoke;
  flex: 0.1;
  display: flex;
}

@media screen and (max-width: 1050px) {
  .category-pic img {
    width: 50%;
  }
  .category-pic {
    margin-top: -50px;
  }
}
@media screen and (max-width: 550px) {
  .category-pic h3 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }
  .category.section__padding {
    padding: 1rem 0px !important;
  }
}
@media screen and (max-width: 500px) {
  .category-bottom-input {
    display: none;
  }
}
