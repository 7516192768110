.categories-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 90%;
}
.categories-container-text h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  color: #ffffff;
  margin-bottom: 1rem;
}
.categories-container-card {
  margin: 0 -5px;
}
.categories-container-card:after {
  content: "";
  display: table;
  clear: both;
}
.categories-card-column {
  float: left;
  width: 25%;
}
.categories-card {
  /* background: #2a2d3a; */
  border-radius: 20px;
  /* padding: 11px 12px 19px 11px; */
  margin-inline: 10px;
  margin-bottom: 20px;
  position: relative;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.categories-card:hover {
  transform: scale(1.02);
}

.categories-card img {
  border-radius: 20px;
  width: 100%;
  max-height: 16vw;
  min-height: 16vw;
  object-fit: cover;
  opacity: 0.7;
}
.categories-card-top p {
  /* font-family: var(--font-family);
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  
  margin-top: 15px; */

  color: #ffffff;
  font-size: 12px;
  line-height: 18px;
  margin-top: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: sans-serif;
  font-weight: bold;
  font-size: x-large;
  line-height: 100%;
  text-align: center;
}
.categories-card-bottom {
  display: flex;
  justify-content: space-between;
}
.categories-card-bottom p {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* text-align: right; */
  color: #ffffff;
}
.categories-card-bottom p:last-child {
  font-weight: normal;
}
.categories-card-bottom span {
  font-weight: normal;
}

.load-more {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.load-more button {
  background: transparent;
  border: 1px solid var(--primary-color);
  margin: 0 1rem;
  color: var(--primary-color);
  border-radius: 10px;
  padding: 10px 100px;
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

@media screen and (max-width: 1440px) {
  .categories-card-column {
    width: 25%;
  }
  .categories-card img {
    border-radius: 20px;
    width: 100%;
    max-height: 18vw;
    min-height: 18vw;
    object-fit: cover;
    opacity: 0.7;
  }
}

@media screen and (max-width: 1200px) {
  .categories-card-column {
    width: 33.33%;
  }
  .categories-card img {
    border-radius: 20px;
    width: 100%;
    max-height: 22vw;
    min-height: 22vw;
    object-fit: cover;
    opacity: 0.7;
  }
}

@media screen and (max-width: 900px) {
  .categories-card-column {
    width: 50%;
  }
  .categories-card img {
    border-radius: 20px;
    width: 100%;
    max-height: 26vw;
    min-height: 26vw;
    object-fit: cover;
    opacity: 0.7;
  }
}

/* .categories-card .categories-title {
  text-align: center;
  border-radius: 0px 0px 20px 20px;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 30px;
  line-height: 100%;
  margin-top: -80px;
  height: 40px;
} */

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 1rem 14px !important;
  }
  .categories-card {
    margin-bottom: 10px;
    margin-inline: 5px;
    position: relative;
  }
  .categories-card-top p {
    font-size: 12px;
    line-height: 18px;
    margin-top: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: sans-serif;
    font-weight: bold;
    font-size: large;
    line-height: 100%;
    text-align: center;
  }
  .categories-card-bottom p {
    font-size: 10px;
    line-height: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: sans-serif;
    font-weight: bold;
    font-size: 30px;
    line-height: 100%;
    text-align: center;
  }
  .categories-container {
    width: 100%;
  }
  .categories-card img {
    border-radius: 20px;
    width: 100%;
    max-height: 32vw;
    min-height: 32vw;
    object-fit: cover;
    opacity: 0.7;
  }
}
