.item {
  display: flex;
  padding: 0 6rem;
  font-family: var(--font-family);
}
.item-image {
  flex: 1;
  display: grid;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(165, 165, 165, 0.1);
  padding-right: inherit;
}
.item-image img {
  width: 100%;
  height: 25vw;
  border-radius: 70px;
  padding: 50px;
  object-fit: cover;
}
.item-content {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 3rem;
  position: relative;
}

.item-content-title h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  color: #ffffff;
}
.item-content-title p {
  font-family: var(--font-family);
  font-weight: normal;
  font-size: 20px;
  line-height: 21px;
  color: #ffffff;
}
.item-content-title span {
  font-weight: 600;
}
.item-content-creator {
  margin-top: 30px;
}
.item-content-creator p {
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
}
.item-content-creator div {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.item-content-creator div:last-child p {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
}
.item-content-creator img {
  border-radius: 50%;
  margin-right: 10px;
  width: 50px;
  height: 50px;
}
.item-content-detail {
  margin-top: 10px;
  margin-bottom: 30px;
  border-top: 1px solid rgba(165, 165, 165, 0.1);
  padding-top: 10px;
}
.item-content-detail p {
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  /* width: 500px; */
}
.item-content-buy .primary-btn {
  background: var(--primary-btn);
  border-radius: 10px;
  border: none;
  padding: 0.75rem 3rem;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: #fff;
  margin-right: 20px;
}
.item-content-buy .secondary-btn {
  background: transparent;
  border-radius: 10px;
  border: 1px solid var(--primary-color);
  padding: 0.7rem 3rem;
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: var(--primary-color);
}
.item-content-action {
  display: flex;
  position: absolute;
  right: 0;
}
.item-content-action p {
  cursor: pointer;
  font-size: 18px;
  color: white;
  background: var(--primary-btn);
  margin-inline: 10px;
  border-radius: 5px;
  padding: 5px 5px 0px 5px;
}
@media screen and (max-width: 1050px) {
  .item {
    flex-direction: column;
  }
  .item-content {
    margin: 0 0 3rem;
  }
  .item-image {
    border-right: unset;
    padding-right: unset;
    display: flex;
  }
  .item-content-detail p {
    width: 100%;
  }
  .item-image img {
    width: 100%;
    height: 40vw;
    border-radius: 40px;
    padding: 20px;
    margin-top: 20px;
    object-fit: cover;
  }
}

@media screen and (max-width: 650px) {
  .item-content h1 {
    font-size: 28px;
    line-height: 42px;
  }
  .item-content p {
    font-size: 16px;
    line-height: 24px;
  }
  .item-content-title p {
    font-size: 20px;
    line-height: 24px;
  }
  .item-content__people {
    flex-direction: column;
  }
  .item-content__people p {
    margin: 0;
  }
  .item-content__input input,
  .item-content__input button {
    font-size: 16px;
    line-height: 24px;
  }
  .item-content-buy .primary-btn {
    padding: 0.75rem 20px;
  }
  .item-content-buy .secondary-btn {
    padding: 0.75rem 33px;
  }
  .item-image img {
    width: 100%;
    height: 45vw;
    border-radius: 40px;
    padding: 10px;
    margin-top: 20px;
    object-fit: cover;
  }
}
@media screen and (max-width: 550px) {
  .item-content {
    padding: 0 2rem;
  }
  .item-image img {
    width: 100%;
    height: 40vh;
    border-radius: 40px;
    padding: 10px;
    margin-top: 20px;
    object-fit: cover;
  }
}

@media screen and (max-width: 490px) {
  .item-content h1 {
    font-size: 28px;
    line-height: 42px;
  }
  .item-content p {
    font-size: 14px;
    line-height: 26px;
  }
  .item-content-title p {
    font-size: 20px;
    line-height: 24px;
  }
  .item-content__input input,
  .item-content__input button {
    font-size: 12px;
    line-height: 16px;
  }
  .item {
    padding-inline: 1rem !important ;
    padding-top: 1rem !important;
  }

  .item-image img {
    padding: 0;
    border-radius: 20px;
    /* margin-bottom: 25px; */

    width: 100%;
    height: 30vh;
    border-radius: 40px;
    padding: 5px;
    object-fit: cover;
  }

  .item-content-buy .primary-btn {
    margin-right: 10px;
  }
  .item-content-action {
    top: 15%;
  }
  .item-content-action p {
    margin-inline: 5px;
  }
}
