.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.header-content div {
  background: var(--primary-btn);
  width: 100%;
  height: 300px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: auto;
}
.header-content div h1 {
  font-family: var(--font-family);
  width: 70%;
  font-weight: bold;
  font-size: 48px;
  line-height: 105.7%;
  color: #ffffff;
  padding: 1rem;
  margin-left: 2rem;
}
.header-content div img {
  width: 17%;
}
.header-slider {
  /* margin-top: 5rem; */
  /* background: wheat; */
  color: #ffffff;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header-slider .slider {
  width: 70%;
}
.header-slider h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  color: #ffffff;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.slider .slider-card {
  width: 180px !important;
  min-height: 184x !important;
  background: #2a2d3a;
  border-radius: 20px;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  position: relative;
}
.slider-card img {
  border-radius: 12%;
  height: 110px;
  margin-top: 20px;
}

.slider-card-number {
  position: absolute;
  top: 5.5%;
  left: 6.11%;
  background: var(--primary-color);
  width: 33px;
  height: 33px;
  border-radius: 50%;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slider-card p {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin: 12px 0px;
}
.slider-card span {
  font-weight: normal !important;
}

.shake-vertical {
  -webkit-animation: shake-vertical 10s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    2s infinite both;
  animation: shake-vertical 10s cubic-bezier(0.455, 0.03, 0.515, 0.955) 2s
    infinite both;
}
@-webkit-keyframes shake-vertical {
  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  80% {
    -webkit-transform: translateY(6.4px);
    transform: translateY(6.4px);
  }
  90% {
    -webkit-transform: translateY(-6.4px);
    transform: translateY(-6.4px);
  }
}
@keyframes shake-vertical {
  0%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  80% {
    -webkit-transform: translateY(6.4px);
    transform: translateY(6.4px);
  }
  90% {
    -webkit-transform: translateY(-6.4px);
    transform: translateY(-6.4px);
  }
}

.slider-img {
  position: relative;
}
.verify {
  position: absolute;
  right: 5px;
  bottom: 2px;
}

.showcase {
  width: 90%;
  height: 40vw;
  border-radius: 20%;
}

.showcase img {
  width: 100%;
  height: 40vw;
}

.main-banner {
  width: 100%;

  position: relative;
  padding: unset;
}
.main-banner img {
  width: 100%;
  height: 38vh;
  opacity: 0.7;
  /* border-radius: 12px; */
  object-fit: cover;
  padding: unset;
}

.main-banner h3 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  color: #ffffff;
  position: absolute;

  margin-top: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* media  */
@media screen and (max-width: 1600px) {
  .header-slider .slider {
    width: 90%;
  }

  .showcase {
    width: 90%;
    height: 26vw;
    border-radius: 2vw;
  }

  .showcase img {
    width: 100%;
    height: 26vw;
    border-radius: 20px;
    object-fit: cover;
  }
  .main-banner img {
    height: 40vh;
  }
}
@media screen and (max-width: 1350px) {
  .showcase {
    width: 90%;
    height: 30vw;
    border-radius: 20%;
  }

  .showcase img {
    width: 100%;
    height: 30vw;
  }

  .header-slider .slider {
    width: 100%;
  }

  .header-content div {
    height: 200px;
  }
  .header-content div h1 {
    font-size: 36px;
    line-height: 32px;
    /* width: 100%; */
  }
  .main-banner img {
    height: 36vh;
  }
}
@media screen and (max-width: 550px) {
  .showcase {
    width: 90%;
    height: 40vw;
    border-radius: 20%;
  }

  .showcase img {
    width: 100%;
    height: 40vw;
  }

  .slick-prev,
  .slick-next {
    display: none !important;
  }
  .header-slider h1 {
    font-size: 20px;
    line-height: 30px;
  }
  .slider .slider-card {
    width: 138px !important;
    min-height: 165px !important;
  }
  .slider-card-number {
    width: 24px;
    height: 24px;
  }
  .slider-card p {
    font-size: 14px;
    line-height: 21px;
  }
  .header-content div {
    height: 143px;
  }
  .header-content div h1 {
    font-size: 20px;
    line-height: 24px;
    width: 100%;
  }
  .main-banner img {
    height: 26vh;
  }
}
@media screen and (max-width: 800px) {
  .header-content div img {
    width: 20%;
    margin-right: 10px;
  }
  .header-content div {
    justify-content: unset;
  }
  .header-content div h1 {
    margin-left: 1rem;
  }
  .main-banner img {
    height: 32vh;
  }
}
@media screen and (max-width: 650px) {
  .header-content h1 {
    font-size: 48px;
    line-height: 60px;
  }
  .main-banner img {
    height: 28vh;
  }
}

@media screen and (max-width: 400px) {
  .slider .slider-card {
    margin-right: 1rem;
  }
  .bids {
    padding: 0.7rem 0px !important;
  }
  .main-banner img {
    height: 24vh;
  }
}
@media screen and (max-width: 335px) {
  .slider .slider-card {
    margin-right: 0.5rem !important;
  }
  .main-banner img {
    height: 22vh;
  }
}
