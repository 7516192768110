.contents-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 90%;
}
.contents-container-text h1 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 28px;
  line-height: 42px;
  color: #ffffff;
  margin-bottom: 1rem;
}
.contents-container-card {
  margin: 0 -5px;
}
.contents-container-card:after {
  content: "";
  display: table;
  clear: both;
}

.contents-card {
  background: #2a2d3a;
  border-radius: 20px;
  /* padding: 11px 12px 19px 11px; */
  margin-inline: 10px;
  margin-bottom: 20px;
  position: relative;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  padding: 10px 10px 5px 10px;
}

.contents-card:hover {
  transform: scale(1.02);
}

.contents-card img {
  border-radius: 20px;
  width: 100%;
  max-height: 12vw;
  min-height: 12vw;
  object-fit: cover;
}

.contents-content {
  padding: 0px 10px;
  /* margin-top: 10px; */
  margin: 0px 10px;
}

.contents-card h1 {
  color: #ffffff;
  font-size: 22px;
  line-height: 130%;
  font-family: sans-serif;
  font-weight: bold;
}

.contents-card p {
  border-top: 2px solid rgba(165, 165, 165, 0.1);
  color: #ffffff;
  font-size: 16px;
  line-height: 150%;
  margin-top: 5px;
  font-family: sans-serif;
  font-weight: 400;
  padding: 10px 0px;
}

.contents-card-bottom {
  display: flex;
  justify-content: space-between;
}
.contents-card-bottom p {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* text-align: right; */
  color: #ffffff;
}
.contents-card-bottom p:last-child {
  font-weight: normal;
}
.contents-card-bottom span {
  font-weight: normal;
}

.load-more {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.load-more button {
  background: transparent;
  border: 1px solid var(--primary-color);
  margin: 0 1rem;
  color: var(--primary-color);
  border-radius: 10px;
  padding: 10px 100px;
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.card-column {
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .contents-card {
    margin-bottom: 10px;
    margin-inline: 5px;
    position: relative;
    padding: 10px 10px 5px 10px;
  }
  .contents-container {
    width: 100%;
  }
  .contents-card img {
    width: 100%;
    max-height: 17vw;
    min-height: 17vw;
  }

  .contents-content {
    /* padding: 0px 10px; */
    /* margin-top: 10px; */
    margin: 0px 0px;
  }

  .contents-card h1 {
    font-size: 24px;
  }
  .contents-card p {
    border-top: 2px solid rgba(165, 165, 165, 0.1);
    font-size: 16px;
    padding-top: 15px;
  }
}

@media screen and (max-width: 1200px) {
  .contents-card {
    margin-bottom: 10px;
    margin-inline: 5px;
    position: relative;
    padding: 10px 10px 5px 10px;
  }
  .contents-card img {
    width: 100%;
    max-height: 17vw;
    min-height: 17vw;
  }
  .contents-container {
    width: 100%;
  }
  .contents-content {
    /* padding: 0px 10px; */
    /* margin-top: 10px; */
    margin: 0px 0px;
  }

  .contents-card h1 {
    font-size: 24px;
  }
  .contents-card p {
    border-top: 2px solid rgba(165, 165, 165, 0.1);
    font-size: 16px;
    padding-top: 15px;
  }
}

@media screen and (max-width: 900px) {
  .contents-card img {
    width: 100%;
    max-height: 24vw;
    min-height: 24vw;
  }

  .contents-card {
    margin-bottom: 10px;
    margin-inline: 5px;
    position: relative;
    padding: 10px 10px 1px 10px;
  }
  .contents-content {
    /* padding: 0px 10px; */
    /* margin-top: 10px; */
    margin: 0px 0px;
  }

  .contents-card h1 {
    font-size: 22px;
  }
  .contents-card p {
    border-top: 2px solid rgba(165, 165, 165, 0.1);
    font-size: 16px;
    padding-top: 15px;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 1rem 14px !important;
  }
  .contents-card {
    margin-bottom: 10px;
    margin-inline: 5px;
    position: relative;
    padding: unset;
    padding: 0px 0px 1px 0px;
  }

  .contents-card img {
    width: 100%;
    max-height: 32vw;
    min-height: 32vw;
  }

  .contents-content {
    padding: 0px 10px;
    /* margin-top: 10px; */
    margin: 0px 0px;
  }

  .contents-card h1 {
    font-size: 20px;
  }
  .contents-card p {
    border-top: 2px solid rgba(165, 165, 165, 0.1);
    font-size: 14px;
    margin-top: 5px;
  }

  .contents-container {
    width: 100%;
  }
}
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -5px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 5px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 15px;
}
