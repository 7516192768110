@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

:root {
  --font-family: "Poppins", sans-serif;

  --gradient2: linear-gradient(89.97deg, #eb1484 1.84%, #c81cc5 102.67%);
  --primary-btn: linear-gradient(
    101.12deg,
    #eb1484 27.35%,
    #c91cc3 99.99%,
    #c81cc5 100%,
    #c81cc5 100%
  );

  --primary-color: #eb1484;
  --secondary-color: #c81cc5;

  --color-bg: #24252d;
  --color-card: #2a2d3a;
  --color-footer: #24252d;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #2a2d3a #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 14px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: var(--color-card);
  border-radius: 10px;
  border: 3px none #ffffff;
}

.top-to-btm {
  position: relative;
}
.icon-position {
  position: fixed;
  bottom: 40px;
  right: 25px;
  z-index: 20;
}
.icon-style {
  background-color: #551b54;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  color: #fff;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all 0.5s ease-in-out;
}
.icon-style:hover {
  animation: none;
  background: #fff;
  color: #551b54;
  border: 2px solid #551b54;
}
@media screen and (max-width: 550px) {
  .icon-position {
    bottom: 60px;
  }
}

@keyframes movebtn {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}
